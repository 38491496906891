//
// Accessory purchase card
//

.purchase-accessory-card {
	margin-bottom: 0 !important;
	text-align: center
}

.cart-button {
	border-width: 0;
}
.delete {
	color: red;
	&:hover {
		color: lightcoral;
		border-width: 0;
		box-shadow: none;
		opacity: 0.9;
		background-color: transparent;
		text-decoration: none;
	}
}
.add {
	color: black;
	&:hover {
		border-width: 0;
		color: #0b2139;
		box-shadow: none;
		opacity: 0.9;
		background-color: transparent;
		text-decoration: none;
	}
}

.aux-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	// height: 100%;
	// font-size: x-small;
	width: 90%;
	// display: flex;
	// align-items: center;
}

.center-elements {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-block:hover > .invis {
	visibility: visible;
}

.invis {
	visibility: hidden;
}

.card-border {
	border: 1px solid #ffffff !important;
}

.card-block {
	// display: inline-block;
	// height: 25vh;
	// background-color: #fff;
	// min-width:200px;
	// max-width:300px;
	// width: 18vh;
	// border: none;
	background-position: center;
	background-size: cover;
	// transition: all 0.2s ease-in-out !important;
	border-radius: 24px;
	// &:hover{
	// 	transform: translateY(-5px);
	// 	box-shadow: none;
	// 	opacity: 0.9;
	// }
}
