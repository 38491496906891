.overflow-auto {
  overflow: auto !important;
}

.min-height-0 {
	  min-height: 0 !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}