//
// Purchase Preview
//

.scrolling-wrapper {
	overflow-x: auto;
	white-space: nowrap;
}

.google-map-container {
	border-radius: var(--bs-card-border-radius);
	margin-top: 0rem;
	margin-bottom: 0rem;
	height: 25vh;
	min-height: 250px;
	width: 100%;
	border: 1px solid #dbdbdb;
}

// .purchase-preview-card{
// 	height: calc(100vh - 55px);
// }

// .accessory-purchase{
// 	transition: all 0.2s ease-in-out !important;
// 	&:hover{
// 		transform: translateY(-5px);
// 		box-shadow: none;
// 		opacity: 0.9;
// 	}
// }
