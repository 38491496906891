.hor-stack {
	width: fit-content;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	text-align: center;
}

.min-max-label {
	font-size: 0.75rem !important;
	color: gray !important;
}

// changing highlight color of the bootstrap input
.form-control:focus {
	border-color: var(--brand-color);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--brand-color-light);
}

select:focus {
	border-color: var(--brand-color);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--brand-color-light);
}

// For the thumb on the range slider
.custom-range::-webkit-slider-thumb {
	background: var(--brand-color);
}

.custom-range::-webkit-slider-thumb {
	background: var(--brand-color);
}

.custom-range::-moz-range-thumb {
	background: var(--brand-color);
}

.custom-range::-ms-thumb {
	background: var(--brand-color);
}

-webkit-slider-thumb:active {
	background-color: var(--brand-color-light);
}
-webkit-slider-thumb,
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
	box-shadow: var(--brand-color-light);
}

/* Define a CSS class for the element */
.bestMonthlyPayment {
	display: flex;
	font-weight: 300;
	align-items: center;
	margin: 0;
	margin-left: '0.5rem';
	margin-right: '0.5rem';
	/* Default text decoration and color */
	text-decoration: none;
	color: inherit; /* Use the color inherited from the parent or set a default color */
}

/* Change appearance on hover */
.bestMonthlyPayment:hover {
	font-weight: 300;
	text-decoration: underline;
	cursor: pointer;
	color: var(--brand-color); /* Change color on hover */
}

.howItWorks {
	text-decoration: underline;
	text-decoration-style: dotted;
	text-decoration-color: #c6c6c6;
}

.howItWorks:hover {
	text-decoration: underline;
	cursor: pointer;
}

.footer-top-border-gray {
	border-top: 1px solid #e8e8e8;
}

.disclaimer-text {
	font-size: 12px;
	color: rgb(129, 129, 129);
	font-weight: 300;
}

//
//
//

.left-block {
	float: left;
}

.right-block {
	float: right;
}

.block-top {
	font-size: 1.5rem;
	font-weight: 600;
	padding-bottom: 0.2rem;
	text-align: center;
}

.block-bottom {
	font-size: 0.75rem;
	font-weight: 400;
	padding-bottom: 0;
	text-align: center;
}
